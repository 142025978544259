<template>
  <select
      class="list-input"
      @change="handleInput">
    <option v-if="!required" />
    <template
        v-for="option in options"
        :key="option && option[valueField]">
      <option
          v-if="option"
          :disabled="option.disabled === true"
          :selected="(modelValue
        && (modelValue[valueField] === option[valueField])) ? 'selected' : undefined"
          :value="option[valueField]">
        <slot :option="option">
          {{ option[labelField] }}
        </slot>
      </option>
    </template>
  </select>
</template>
<script>

import {
  toRefs,
  watch,
} from 'vue';

export default {
  props: {
    modelValue: {
      type: Object,
      default: () => null,
    },
    options: {
      type: Array,
      default: () => null,
    },
    required: {
      type: Boolean,
      default: () => false,
    },
    valueField: {
      type: String,
      default: () => 'id',
    },
    labelField: {
      type: String,
      default: () => 'label',
    },
  },
  emits: ['update:model-value'],
  setup(props, { emit }) {
    const {
      options, valueField, modelValue, required,
    } = toRefs(props);

    const getValue = (value, opts) => opts?.find(
      (opt) => opt && `${opt[valueField.value]}` === `${value}`,
    );

    const checkValue = () => {
      if (!options.value) {
        return;
      }

      const value = (modelValue.value)
        ? getValue(modelValue.value[valueField.value], options.value)
        : null;
      if (required.value && options.value?.length && value === null) {
        emit('update:model-value', options.value[0]);
      } else if (value !== modelValue.value) {
        emit('update:model-value', value);
      }
    };

    if (options.value?.length) {
      checkValue();
    }

    watch(options, checkValue);

    return {
      handleInput(ev) {
        emit('update:model-value', getValue(ev.target.value, options.value));
      },
    };
  },

};
</script>
<style>
.list-input {
  display: block;

  width: 100%;

  padding: var(--dimension-x-small) var(--dimension-medium-large) var(--dimension-x-small) var(--dimension-small);

  color: var(--color-foreground);

  background-color: transparent;

  background-image: url("@carbon/icons/svg/chevron--down.svg");
  background-repeat: no-repeat;
  background-position: right var(--dimension-small) center;
  background-size: .75rem .75rem;
  border: none;
  border-radius: 0;

  cursor: pointer;

  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;

  white-space: nowrap;
  text-overflow: ellipsis;

  appearance: none;

  &:focus {
    outline: none;
  }

  > option {
    color: var(--color-foreground);
  }

  &[disabled] {
    @media (any-hover: hover) {
      &:hover {
        background-position: center right .75em, 0 0;
      }
    }
    color: var(--color-black);
    background-position: center right .75em, 0 0;
    cursor: not-allowed;
  }

}
</style>
